<template>
  <div>


    <v-dialog v-model="cahiers_dialog" max-width="700" scrollable>
      <v-card>
        <v-card-title>
          {{ $t('generic.lang_chooseCashier') }}
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
                v-for="(cashier, index) in cashierIDs.cashiers"
                :key="index"
                @click="selectCashierID(cashier.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                    (cashier.aliasName !== "" && cashier.aliasName !== null && cashier.aliasName !== undefined) ? cashier.aliasName : $t('generic.lang_kasse') + ' ' + cashier.id
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div style="height:calc(100vh - 48px);">
      <Login/>
    </div>

    <v-app-bar id="login_app_bar" class="text-center" dense elevation="12">
      <div class="text-center" style="width: 100% !important;">

        <v-menu>
          <template v-slot:activator="{on}">
            <b-button v-on="on" class="pa-0 transparent text-center ma-0 mx-0" style="width: 60px">
              <i class="btn-icon-wrapper  text-center">
                <font-awesome-icon :icon="['fal','language']" class="text-muted mx-auto ma-auto"
                                   style="font-size: 18px"/>
              </i>
              <span class="text-muted" style="font-size: 10px; position: absolute; right: 0; left: 0;bottom: -10px">
            {{ currentLang }}
          </span>
            </b-button>
          </template>
          <v-list class="pa-0">
            <v-list-item v-for="(lang , i) in this.languages" :key="i" class="pl-0 pt-0 pb-0"
                         @click="changeLanguage(i)">
              <v-list-item-action class="pa-0 px-0" style="">
                <country-flag :country="lang.flag" class="ma-0 mx-0"
                />
              </v-list-item-action>
              <v-list-item-content class="pt-1">
                <v-list-item-title>
                  {{ lang.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-if="showToolbar" class="pl-1 pr-1 text-muted" style="font-size:11px" text @click="cahiers_dialog=true">
          <i class="btn-icon-wrapper">
            {{ this.$t('generic.lang_kasse') + ' ' + api.auth.cashierID }}
          </i>
        </v-btn>


        <v-dialog
            v-if="showToolbar"
            v-model="confirm_logout_dialog"
            max-width="290"
            persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn

                v-bind="attrs" v-on="on" class="pl-1 pr-1 text-center" text>
              <i class="btn-icon-wrapper">
                <font-awesome-icon :icon="['fal','retweet']" class="text-muted" style="font-size: 18px"/>
              </i>
              <!--          <span class="text-muted" style="font-size: 7px; position: absolute; right: 0; left: 0;bottom: -10px">
                          Systemwechsel
                        </span>-->
            </v-btn>

          </template>
          <v-card>
            <v-card-title class="headline">
              {{ $t('generic.lang_changeSystem') }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  autofocus
                  color="success darken-1"
                  text
                  @click="confirm_logout_dialog = false"
              >
                {{ $t('generic.lang_no') }}
              </v-btn>
              <v-btn
                  color="error darken-1"
                  text
                  @click="changeSystem"
              >
                {{ $t('generic.lang_yes') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
            v-if="!this.$vuetify.breakpoint.smAndDown"
            class="pl-1 pr-1 text-center" icon
            text
            @click="toggleKeyboard">
          <i class="btn-icon-wrapper">
            <font-awesome-icon :icon="['fal','keyboard']"
                               :style="{'color': (touchkeyboard.settings.enabled ?  '#3C7CBA !important' : '')}"
                               class="text-muted" style="font-size: 18px"/>
          </i>
        </v-btn>

        <v-btn class="pl-1 pr-1 text-muted" style="font-size:11px" text @click="setFullScreen">
          <i class="btn-icon-wrapper">
            <v-icon v-if="!this.fullscreen">fullscreen</v-icon>
            <v-icon v-else>fullscreen_exit</v-icon>
          </i>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Login from "./Login";
import {mapGetters, mapState} from 'vuex';


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from "@fortawesome/fontawesome-svg-core";
import {faKeyboard, faLanguage, faRetweet} from "@fortawesome/pro-light-svg-icons";
import CountryFlag from 'vue-country-flag'

library.add(faLanguage, faRetweet, faKeyboard)

export default {
  name: "Index",
  data(){
    return {
      cahiers_dialog: false,
      confirm_logout_dialog: false,
      fullscreen: "",
      languages: {
        de: {
          name: this.$t('generic.lang_germany'),
          flag: 'DE'
        },
        en: {
          name: this.$t('generic.lang_english'),
          flag: 'GB'
        },
        fr: {
          name: this.$t('generic.lang_french'),
          flag: 'FR'
        },
      }
    }
  },
  components: {
    Login,
    'font-awesome-icon': FontAwesomeIcon,
    CountryFlag
  },
  mounted() {
    this.fullscreen = document.fullscreen;
    this.$store.dispatch("app/setFiscalState", true);
  }
  ,
  methods: {
    setFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    changeLanguage(locale) {
      this.$store.dispatch("app/changeLanguage", locale).then((res) => {
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
      });
    }
    ,
    toggleKeyboard() {
      this.$store.dispatch("touchkeyboard/updateSettings", {
        enabled: !this.touchkeyboard.settings.enabled
      });
    }
    ,
    selectCashierID(cashierID) {
      this.$store.dispatch("api/auth/setCashierID", cashierID);
      this.$store.dispatch("items/resetState", []);
      this.$store.dispatch("itemgroups/resetState", []);
      this.$store.dispatch("app/updateInitDataLoadedState", false).then((res) => {
        this.cahiers_dialog = false;
      });
    },
    changeSystem() {
      // CLEAN UP INDEXEDDB DATABASES
      indexedDB.deleteDatabase('3pos');


      var req = indexedDB.deleteDatabase('3pos_ware');
      req.onsuccess = function () {
        console.log("Deleted database successfully");
      };
      req.onerror = function () {
        console.log("Couldn't delete database");
      };
      req.onblocked = function () {
        console.log("Couldn't delete database due to the operation being blocked");
      };
      this.api.auth = {};

      this.$router.go();
    }
  }
  ,
  watch: {
    fullscreen(val) {
      this.$store.commit('user/setFullscreen', val)
      if (val) {
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      } else {
        if (this.fullscreen && document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  }
  ,
  computed: {
    currentLang() {
      return this.languages[this.$root.$i18n.locale].name
    }
    ,
    ...
        mapGetters({
          getFullscreen: 'user/getFullscreen'
        }),
    ...
        mapState([
          'touchkeyboard',
          'api',
          'cashierIDs'
        ]),
    showToolbar() {
      if (this.api.auth !== undefined) {
        if (this.api.auth.accessString !== "" && this.api.auth.apiWebToken !== "" && this.api.auth.posID > 0) {
          return true;
        }
      }

      return false;
    }
  }
  ,
}
</script>

<style scoped>
body {
  background-color: #f1f4f6 !important;
}

#login_app_bar .v-toolbar__content {
  box-shadow: 0px -7px 13px 0px black !important;
}
</style>

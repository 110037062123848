<template>
  <v-dialog v-model="dialog" max-width="600" persistent >

    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed text color="#3C7CBA" class="mx-auto" block v-bind="attrs" v-on="on">
        {{$t('generic.lang_forgotPassword')}}
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="forgotPasswordForm" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row align="center" class="text-center pa-0" no-gutters>
              <v-col class="text-left pb-10" cols="12">
                <h2 class="mb-1 pb-1 pl-0 font-weight-bolder" >
                  {{ $t('generic.lang_enterUserName') }}!
                </h2>

              </v-col>
              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                    v-model="userID"
                    :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                    :disabled="loading"
                    :loading="loading"
                    :min="1"
                    :rules="[rules.required,userIDRule]"
                    class="ma-auto rounded-0"
                    dense
                    label="3POS ID"
                    outlined
                    required
                    type="number"
                    @focus="showTouchKeyboard"
                />
              </v-col>
            </v-row>

          </v-container>
        </v-form>

      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-btn @click="dialog = false" color="error" text>{{$t('generic.lang_close')}}</v-btn>
        <v-spacer/>
        <v-btn :disabled="loading || !valid" :loading="loading" class="text-white mx-auto ma-auto"
               color="#3C7CBA" elevation="0"
               tile @click="sendResetPasswordLink">
          {{$t('support.lang_supportSend')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div ref="onScreenKeyboardDivForgotPassword" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboardForgotPassword.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboardForgotPassword"
                          :cancel="hideTouchKeyboardForgotPassword"
                          :defaultKeySet="touchKeyboardForgotPassword.keySet"
                          :input="touchKeyboardForgotPassword.input"
                          :layout="touchKeyboardForgotPassword.layout"
                          :options="touchKeyboardForgotPassword.options"
                          class="internalWidthExpanded"/>
    </div>
  </v-dialog>
</template>

<script>
import validation from "../../mixins/validation/validation";
import {KEYBOARD} from "../../config";
import {mapState} from "vuex";
import {Events} from "../../plugins/events";

export default {
  name: "ForgotPasswordDialogComponent",
  mixins:[validation],
  data(){
    return{
      dialog:false,
      valid:true,
      loading:false,
      userID:null,
      userIDRule:(v)=>{
        return Number(v)>=100||this.$t('generic.lang_invalid_input')
      },
      KEYBOARD,
      touchKeyboardForgotPassword: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },
  watch:{
    dialog(val) {
      if(val){
        document.addEventListener('click', this.dismissOnScreenKeyboardForgotPassword);
      }else{
        document.removeEventListener('click', this.dismissOnScreenKeyboardForgotPassword);
      }
    }
  },
  computed:{
    ...mapState([
      'touchkeyboard',
    ]),
  },
  methods:{
    sendResetPasswordLink(){
      if (!this.$refs.forgotPasswordForm.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post('update/resetPasswordReq/', {
        resetUserId:this.userID,
      }).then((res) => {
        if(res.status===200){
          this.$refs.forgotPasswordForm.reset();
          this.dialog=false;
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_pleaseCheckEmailForThePasswordResetLink')
          });
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_errorOccurred')
        });
      }).finally(()=>{
        this.loading=false;
      })
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardForgotPassword.input = e.target;
      this.touchKeyboardForgotPassword.layout = e.target.dataset.layout;

      if (!this.touchKeyboardForgotPassword.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardForgotPassword.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardForgotPassword() {
      if (this.touchKeyboardForgotPassword.visible) {
        this.touchKeyboardForgotPassword.visible = false;
      }
    },
    dismissOnScreenKeyboardForgotPassword: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivForgotPassword) {
        if (!this.$refs.onScreenKeyboardDivForgotPassword.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardForgotPassword();
          }
        }
      }
    },

  },
  destroyed() {
    clearInterval(this.clearIntervalID);
  }
}
</script>

<style scoped>

</style>
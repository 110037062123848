<template>
  <div style="height:100vh;">
    <transition appear mode="out-in" name="fade">
      <div class="h-100">
        <b-row class="h-100 no-gutters">
          <b-col class="d-lg-block" lg="8" md="8" sm="12">
            <div
                :style="{'backgroundImage': ` url('${imageUrl}')`,'background-size': 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}"
                class="position-relative h-100 d-flex justify-content-center align-items-center">
              <div id="thubnail_title" class="pa-3" style="color: whitesmoke;">
                <h2> {{
                    (parseInt(this.api.auth.posID) > 0 && this.api.auth !== undefined && parseInt(this.api.auth.posID) > 0) ? $t('generic.lang_branch') + ' ' + this.api.auth.posID : ''
                  }} {{
                    (parseInt(this.api.auth.cashierID) > 0 && this.api.auth !== undefined && parseInt(this.api.auth.posID) > 0) ? ' | ' + this.$t('generic.lang_kasse') + this.api.auth.cashierID : ''
                  }}</h2>
                <p style="font-size: 120%;">
                  {{ $t('generic.lang_poweredBy3posSystem') }}
                </p>

              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-center align-items-center" lg="4" md="4" sm="12">
            <v-container class="" fluid>
              <b-col class="mx-auto pl-6" lg="9" md="10" sm="12"
                     style="text-align:left; position: absolute;top: 7px;left:0;right:0">
                <img class="" src="@/assets/images/posilinio-logo-name.svg" width="90px">
              </b-col>
              <b-col class="mx-auto  app-login-box pa-0" lg="9" md="10" sm="12">
                <LoginForm class="" @eventname="updateImageStart"></LoginForm>
              </b-col>
            </v-container>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import LoginForm from "../../components/auth/LoginForm";
import {mapState} from "vuex";

export default {
  name: "Login",

  components: {
    LoginForm,

  },
  computed: {
    ...mapState([
      'api',
    ]),
  },
  data: () => ({
    imageUrl: require("@/assets/images/auth/background.jpg")
    //imageUrl: "https://img.3pos.de/unsafe/filters:quality(80)/billsys_23/original/bf92941fe45592f56cf554bbf4a38dfa.png"
  }),
  methods: {
    updateImageStart(variable) {
      this.imageUrl = variable;
    }
  },
}
</script>


<style scoped>
#thubnail_title {
  color: whitesmoke;
  background-image: linear-gradient(60deg,#29323cAB,#485563AB);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  border-radius: 0px 9px 9px;
}
</style>

